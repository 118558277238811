<template>
  <div class="main">
    <div class="reset-password" v-if="validLink">
      <form class="login-form">
        <div class="logo-box">
          <img
              class="logo"
              src="../../assets/images/Amblem2@2x.webp"
              alt="Glocal Logo"
          />
        </div>
        <h1 class="heading">Reset Password</h1>
        <div class="form-fields">
          <input
              type="password"
              class="form-text"
              required
              placeholder="Type New password"
              v-model="password"
          />
          <input
              type="password"
              required
              class="form-text"
              placeholder="Retype New password"
              v-model="confirm_password"
          />
          <p v-if="errorMessage" class="error">{{ errorMessage }}</p>
          <a-button
              class="form-btn btn-primary"
              :loading="loading"
              @click="resetPassword"
          >
            Reset Password
          </a-button>
        </div>
      </form>
    </div>
    <div class="invalid-link" v-if="urlError">
      {{ urlError }}
    </div>
  </div>
</template>

<script>
import ApiService from "../../services/api.service.js";

export default {
  data() {
    return {
      validLink: false,
      urlError: "",
      password: "",
      confirm_password: "",
      errorMessage: "",
      successMessage: "",
      loading: false,
    };
  },
  mounted() {
    this.verifyUrl({
      token: this.$route.query.token,
      uidb64: this.$route.query.uidb64,
    });
  },
  methods: {
    async verifyUrl(requestObj) {
      await ApiService.post("user/password-reset-verify/", requestObj)
          .then(() => {
            this.validLink = true;
          })
          .catch((err) => {
            this.urlError = err.response.data.error;
          });
    },
    async resetPassword() {
      this.loading = true;
      this.errorMessage = "";
      if (this.password === this.confirm_password) {
        const requestObj = {
          password: this.password,
          token: this.$route.query.token,
          uidb64: this.$route.query.uidb64,
        }
        await ApiService.post("user/password-reset-confirm/", requestObj)
            .then(() => {
              this.successMessage = "Password Reset Successfully";
              this.$router.replace("/");
            })
            .catch((err) => {
              if (err.response.data.error) {
                this.errorMessage = "Invalid Link"
              } else {
                err.response.data.password.forEach((val) => {
                  this.errorMessage += val + "\n";
                })
              }
            })
      } else {
        this.errorMessage = "Passwords do not match";
      }
      this.loading = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.main {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  background-color: $color-dark-grey-2;

  .reset-password {
    width: 37.5rem;
    padding: 4rem 2rem;
    box-shadow: 10px 10px 25px $color-dark-grey-1;
    background-color: $color-white;

    .heading {
      text-align: center;
      font-size: 2rem;
      font-family: $font-secondary-bold;
      padding-top: 1.9rem;
    }
  }

  .invalid-link {
    font-size: 2.2rem;
    font-family: $font-secondary-bold;
  }
}
</style>